<template>
    <div style="width:98%; margin-left:1%">
        <div style="text-align: center; margin-top: 20px">
            <a class="title_a">Talisman</a><br>
            <a class="title_a1">Add Some Luck To Your Future</a>
        </div>
        <div>
            <div style="margin-top: 40px; display: flex; flex-wrap: wrap;">
                    <div class="good"  v-for="item in tableData" :key="item.id" >
                        <el-card class="card" :body-style="{ padding: '0px' }" shadow="hover">
                            <router-link class="router-link" :to="{path:'goodDetial',query:{id: item.id}}">
                                <img :src="item.img" class="good_image">
                            </router-link>
                            <div class="good_name">
                                <span class="good_a30">{{ item.name }}</span>
                            </div>
                            <div class="pad15">
                                <div style="width: 50vw">
                                    <span class="good_a20">{{ item.types }}</span>
                                    <div class="good_a35">$ {{ item.price }}</div>
                                </div>
                                <div>
                                    <el-button type="primary" class="good_Button" @click="buyInsurance(item.id)">ADD</el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
            </div>
        </div>

        <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" :destroy-on-close="true">
            <el-container>
                <el-header>
                    <div style="border-bottom:2px solid #000">
                        <span class="drawer_title">MY LINGFU</span>
                    </div>
                </el-header>
                <el-main>
                    <div>
                        <el-card v-for="item in tableData1" :key="item.id" class="drawer_card" :body-style="{ padding: '5px' }" shadow="hover">
                            <div style="display: flex">
                                <div style="width: 40%">
                                    <img class="drawer_img" :src="item.img">
                                </div>
                                <div style="width: 60%">
                                    <div style="display: flex">
                                        <div class="name_goods">
                                            <span class="drawer_name">{{ item.name }}</span>
                                        </div>
                                        <div class="refund_order">
                                            <i class="el-icon-close" v-if="item.state == '待支付'" @click="del(item.id)"></i>
                                        </div>
                                    </div>
                                    <div style="display:flex">
                                        <div style="width: 40%">
                                            <div class="mt10_h20">
                                                <div class="mt80_h20">
                                                    <span class="drawer_name">${{ item.total }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 60%;">
                                            <div style="display: flex">
                                                <div class="desc">
                                                    <span class="desc_info" v-if="item.state== '已支付'" @click="refundOrder(item)">退款</span>
                                                    <span class="desc_info" v-if="item.state== '已退款'" @click="refundsGet(item)">详情</span>
                                                </div>
                                                <div class="button_pay">
                                                    <el-button class="drawer_button" type="primary" v-if="item.state== '待支付'" @click="payPal(item)">GET</el-button>
                                                    <el-button class="drawer_button" type="primary" v-if="item.state== '已支付'">DETIAL</el-button>
                                                    <el-button class="drawer_button" type="primary" v-if="item.state== '已退款'">REBUY</el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-main>
                <el-footer class="height100">
                    <!--<div style="display:flex">
                        <div style="width: 50%">
                            <span class="drawer_name">T o t a l</span>
                        </div>
                        <div style="width: 50%; text-align:right">
                            <span class="drawer_name">${{ tableData.price }}</span>
                        </div>
                    </div>
                    <div style="">
                    </div>-->
                </el-footer>
            </el-container>
        </el-drawer>


        <!-- 购物车按钮 -->
        <div class="cart">
            <el-button class="cart_button"
            size="big" 
            circle
            @click="openDrawer()"
            >
                <i class="el-icon-shopping-cart-1"></i>
            </el-button>
        </div>

    </div>
</template>

<script>
import insurance from "@/views/Insurance.vue";

export default {
    name: "Insurance",
    data() {
        return {
            activeName: 'first',
            dialogFormVisible: false,
            types: '',
            price: 0,
            multipleSelection: [],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            insurance: [],
            tableData: [],
            tableData1: [],
            total: 0,
            pageNum: 1,
            pageSize: 999,
            name: "",
            form: [],
            drawer: false,
            approveUrl: [],
        }
    },
    created() {
        this.load()
    },
    methods: {
        load() {
            this.request.get("/insurance/page", {
                params: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    name: this.name,
                    price: this.price,
                    types: 'Talisman',
                    //types: 'Houres_fengshui',
                    //types: this.types,
                }
            }).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        buyInsurance(insuranceId){
            this.openDrawer();
            if (this.user.trueName === null || this.user.trueId === null || this.user.trueName === "" || this.user.trueId === "") {
                this.$message.error("请先前往实名")
            }else {
                this.request.post('/orders/' + insuranceId).then(res => {
                    if (res.code === '200') {
                        this.$notify({
                            title: '成功加入订单',
                            message: '请前往支付',
                            type: 'success',
                            position: 'top-left'
                        });
                        this.addOrders();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        addOrders() {
            this.request.get("/orders/getByUserId", {
                params: {
                    userId: this.user.id,
                }
            }).then(res => {
                this.tableData1 = res.data
                console.log(this.tableData1);
            })
        },
        openDrawer() {
            this.addOrders();
            this.drawer = true;
        },
        del(id) {
            this.request.delete("/orders/" + id).then(res => {
                if (res.code === '200') {
                    this.$message.success("取消成功")
                    this.addOrders();
                } else {
                    this.$message.error("删除失败")
                }
            })
        },
        reset(){
            this.name = ""
            this.types = ""
            this.price = ""
            this.load()
        },
        handleSizeChange(pageSize) {
            console.log(pageSize)
            this.pageSize = pageSize
            this.load()
        },
        handleCurrentChange(pageNum) {
            console.log(pageNum)
            this.pageNum = pageNum
            this.load()
        },
        open(item){
          this.dialogFormVisible = true
            this.form = item
        },
        handleClick(){
        },
        detial(){
            this.$router.push({path: 'goodDetial',query:{id: this.tableData.id}})
        },

        payPal(row){
            /*this.request.post('/createOrder' , {
                "no": row.no,
            }).then(res => {
                this.approveUrl = res.approveUrl
                console.log(row.no);
                window.open(this.approveUrl)
            })*/
            this.request({
                url: '/createOrder',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    no: row.no,
                    total: row.total,
                    name: row.name
                }
            }).then((res)=> {
                this.approveUrl = res.approveUrl
                window.open(this.approveUrl)
            })
        },

        refundOrder(row) {
            this.$confirm('此操作将进行退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.request({
                    url: '/refundOrder',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        orderId: row.alipayNo,
                        price: row.total,
                        invoiceId: row.no
                    }
                }).then((res)=> {
                    this.$message.success("退款成功")
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退款'
                });          
            });
        },
        refundsGet(row){
            console.log(row.refundId);
            this.request({
                url: '/refundsGet',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    refundId: row.refundId,
                }
            }).then((res)=> {
                console.log(res);
            })
        },
    }
}
</script>

<style scoped>
.title_a {
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 12px;
}
.title_a1 {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    word-spacing: 5px;
}
.good {
    width: 25%;
    height: 650px;
    text-align: center;
}
.good_img {
    width: 95%;
}
.card {
    height: 650px;
    border-radius: 0;
    border: 0 ;
    text-align: left;
    padding: 5px;
}
.good_image {
    width: 100%;
}
.good_name {
    height:60px;
    padding: 15px;
}
.good_a {
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    line-height: 30px;
}
.good_a20 {
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    line-height: 30px;
}
.good_a30 {
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    line-height: 30px;
}
.good_a35 {
    font-size: 35px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    line-height: 30px;
    margin-top: 10px;
}
.good_Button {
    width: 120px;
    background-color: #310052;
    color: #fff;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    margin-top: 30px;
    margin-left: 123px;
    border-radius: 0;
    border: #fff;
    border-style: double;
}
.router-link {
  text-decoration: none;
}
.el-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}
.el-header {
    height: 40px !important;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.drawer_title {
    font-size: 30px;
}
.drawer_card {
    margin-top: 20px;
    margin-left: 1%;
    width: 98%;
    height: 190px;
    border: none;
    border-bottom: rgb(219, 219, 219) 1px solid;
    border-radius: 0;
}
.drawer_img {
    width: 170px;
}
.drawer_name {
    font-size: 20px;
}
.el-footer {

}
.cart {
    position: fixed;
    bottom: 50px;
    right: 100px;
    z-index: 0;
}
.drawer_button {
    width: 100px;
    height: 35px;
    margin-top: 5px;
    font-size: 17px;
    background-color: #310052;
}
.pad15 {
    padding: 15px; 
    display: flex;
}
.cart_button {
    width: 60px; 
    height: 60px; 
    background-color: #310052;
    border: 1px sloa #fff;
    border-style: double;
}
.el-icon-shopping-cart-1 {
    font-size: 28px; 
    color: #fff
}
.mt10_h20 {
    margin-top: 10px; 
    height: 20px
}
.mt80_h20 {
    margin-top: 80px; 
    height: 20px
}
.desc {
    margin-top: 80px; 
    height: 20px;
    width: 30px;
    margin-left: 10%
}
.desc_info {
    font-size: 15px; 
    color:rgb(95, 95, 95); 
    cursor: pointer
}
.el-icon-close {
    font-size: 25px
}
.button_pay {
    margin-top: 70px;
    margin-left: 20px; 
}
.height100 {
    height: 100px;
}
.name_goods {
    display: flex;
    width: 280px;
    height: 60px;
}
.refund_order {
    margin-top: 5px; 
    height: 20px;
    text-align: right;
}
</style>
